import React from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useEventDivisions } from "./../util/db";
import "./DashboardItems.scss";
import EventDivisionPlayers from './EventDivisionPlayers'
import EventDivisionPlayersNotGolf  from './EventDivisionPlayersNotGolf'

function EventDivisions(props) {
  // const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEventDivisions(props.eventId);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      <div className="DashboardItems__card-items-wrapper">
        
          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>No</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <div>
                {items.map((item, index) => (
                  <Card className="card-items mb-5" key={index}>
                    <Card.Header
                      as="h5"
                      className="d-flex justify-content-between align-items-center"
                      style={{background:"#35155D", text:"white"}}
                    >
                      <Card.Title style={{color:"#ffffff"}}>
                    {item.divisions.name}
                    </Card.Title>
                    </Card.Header> 
                    <Card.Body style={{background:"#8CABFF"}}>
                      {props.scoringOrderGolf ?
                      <>
                      <EventDivisionPlayers isEventComplete={props.isEventComplete} event_divisions_id={item.event_divisions_id} division_id={item.division_id} eventId={props.eventId}/>
                      </>
                      : 
                      <>
                      <EventDivisionPlayersNotGolf isEventComplete={props.isEventComplete} event_divisions_id={item.event_divisions_id} division_id={item.division_id} eventId={props.eventId}/>
                      </>
                      }  
                    </Card.Body>
                  </Card>
                ))}
            </div>
          )}
        
      </div>

    </>
  );
}

export default EventDivisions;
