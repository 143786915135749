import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import FormAlert from "./FormAlert";
import { useSideGamePlayersBySideGame } from "./../util/db";
import "./DashboardItems.scss";

function EventSideGamePlayers(props) {

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useSideGamePlayersBySideGame(props.sideGameId);

// console.log(items)
  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items mb-5">
        <Card.Header
                      as="h5"
                      className="d-flex justify-content-between align-items-center"
                      style={{background:"#35155D", text:"white"}}
                    >
                      <Card.Title style={{color:"#ffffff"}}>
            Players
            </Card.Title>
          </Card.Header>

          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>Nothing yet. Click the button to add your first item.</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <ListGroup variant="flush">
              {items.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className={
                    `d-flex justify-content-between align-items-center` +
                    (item.featured ? " featured" : "")
                  }
                >
                  {item.users_public.name}
                  {/* {item.user_id} */}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      </div>

    </>
  );
}

export default EventSideGamePlayers;
