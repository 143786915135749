import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import EventDivisions from "./EventDivisions"

function EventSection(props) {
  const auth = useAuth();
  const router = useRouter();

  return (
    <>
    {/* <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    > */}
      <Container>

        {
          props.isEventComplete ? <center><h6>Event is complete</h6></center> : <></>
        }

        <Row>
          <Col>
            <EventDivisions isEventComplete={props.isEventComplete} eventId={props.eventId} scoringOrderGolf={props.scoringOrderGolf}/>
          </Col>
        </Row>
      </Container>
    {/* </Section> */}
    
    </>
  );
}

export default EventSection;
