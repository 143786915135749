import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import FormAlert from "./FormAlert";
import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
import { updateEventCalculatePayouts, usePlayersByEvent } from "./../util/db";
import "./DashboardItems.scss";
import { Link } from "../util/router";
import { Form } from "react-bootstrap";

function EventCalculatePayoutsTd(props) {
  // const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersByEvent(props.eventId);

  const [pending, setPending] = useState(false);

  const itemsAreEmpty = !items || items.length === 0;



  const onSubmit = (e) => {
    e.preventDefault();

    // Show pending indicator
    setPending(true);

    // console.log(e.target.formScore.value)
    // console.log(e.target.formUserId.value)


    //update score in db
    // updateScore(e.target.formUserId.value,{score:e.target.formScore.value})
    console.log("button was clicked. calculating payouts")
    // console.log(props.eventId)
    updateEventCalculatePayouts(props.eventId)
        
    setPending(false)
    // Call submit handler for auth type
    // submitHandlersByType[props.type]({
    //   email,
    //   pass,
    // }).catch((error) => {
    //     console.log('ERROR')
    //   setPending(false);
      // Show error alert message
    //   props.onFormAlert({
    //     type: "error",
    //     message: error.message,
    //   });
    // });
  };




  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

        
        
        

      {/* <div className="DashboardItems__card-items-wrapper"> */}
        <Card className="card-items">
        <Card.Header
              as="h5"
              className="d-flex justify-content-between align-items-center"
              style={{background:"#35155D", text:"white"}}
            >
              <Card.Title style={{color:"#ffffff"}}>
                Calculate Payouts
              </Card.Title>
                  
            </Card.Header>

          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>No players yet</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            

                  <>
     
                
                  {/* {console.log(item.user_id)} */}

                <Form onSubmit={onSubmit}>

                    <Form.Group controlId="formUserId">
                        {/* <Form.Label>User Id</Form.Label> */}
                        <Form.Control type="hidden" defaultValue={props.eventId} />
                    </Form.Group>

                    <Button
                        variant="primary"
                        block={true}
                        size="lg"
                        type="submit"
                        // disabled={pending}
                        >Click To Calculate Payouts

                    {pending && (
                        <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden={true}
                        className="ml-2"
                        >
                          <span className="sr-only">Sending...</span>
                        </Spinner>
                    )}
                    </Button>
                </Form>
                </>
          )}
        </Card>
      {/* </div> */}

    </>
  );
}

export default EventCalculatePayoutsTd;
