import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link } from '../util/router';
import { Button } from 'react-bootstrap';

function ScorecardNavbar({currentHole, onLeftNav, onRightNav, showSummary,toggleSummary}) {
  return (
    <Container>
        <Row>
            <Col className='d-flex justify-content-center border border-primary align-items-center' onClick={onLeftNav} style={{background:'#35155D',color:'white'}}>
                {'<'}
            </Col>
            <Col className='d-flex justify-content-center align-items-center' onClick={toggleSummary}>
                {showSummary ?
                    'Summary'
                : 'Hole ' + currentHole
                }
            </Col>
            <Col className='d-flex justify-content-center border border-primary align-items-center' onClick={onRightNav} style={{background:'#35155D',color:'white'}}>
                {'>'}
            </Col>
        </Row>
    </Container>
  )
}

export default ScorecardNavbar