import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import SectionPurple from "./SectionPurple";
import SectionHeader from "./SectionHeader";
import { useAuth } from "../util/auth";
import { useEntriesByEventPlayerAlreadyScored } from "../util/db";
import { useEffect, useState } from "react";
import { requireAuth } from "./../util/auth";
import { useEventName } from "../util/db";

function CtaSectionLoggedIn(props) {
    const auth = useAuth();
    const uid = auth.user.uid
    
    const [isPlayerRegistered, setIsPlayerRegistered] = useState(false)
    const [isRebuys, setIsRebuys] = useState(false)

    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useEventName(props.eventId);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setIsRebuys(items[0].is_rebuys)
        )
    }, [items,itemsStatus]);

    const {
        data: entriesByEventPlayer,
        status: entriesByEventPlayerStatus,
        // error: entriesByEventPlayerError,
    } = useEntriesByEventPlayerAlreadyScored(props.eventId,uid);

    useEffect(() => {//if player has entries already, disable rebuy button
        entriesByEventPlayerStatus !== "loading" && entriesByEventPlayer && entriesByEventPlayer.length > 0 && (
            setIsPlayerRegistered(true)           
        )
    }, [entriesByEventPlayer,entriesByEventPlayerStatus]);

  return (
    <>
        {
            
            !isPlayerRegistered ? 
            <>
                {/* {console.log('player is logged in, but not registered')} */}
                {/* {console.log(entriesByEventPlayer, 'entriesByEventPlayer')} */}
                <SectionPurple
                textColor={props.textColor}
                size={props.size}
                >
                    <Container className="text-center">
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md="auto" className="py-3 px-4">
                                <SectionHeader
                                title={props.title}
                                subtitle={props.subtitle}
                                size={1}
                                spaced={false}
                                className="text-center"
                                />
                            </Col>
                            <Col xs={12} md="auto" className="py-3 px-4">
                                <LinkContainer to={props.buttonPath}>
                                <Button variant={props.buttonColor} size="lg">
                                    {props.buttonText}
                                </Button>
                                </LinkContainer>
                            </Col>
                        </Row>
                    </Container>
                </SectionPurple>
                </>
            : //player is registered
                <>
                    {/* {console.log('player is logged in, and registered')} */}
                    {isRebuys ===true
                    ? 
                        <>
                            {/* {console.log('player is logged in, and registered, rebuys allowed')} */}
                            <SectionPurple
                                textColor={props.textColor}
                                size={props.size}
                            >
                                <Container className="text-center">
                                    <Row className="justify-content-center align-items-center">
                                        <Col xs={12} md="auto" className="py-3 px-4">
                                            <SectionHeader
                                            title={props.title}
                                            subtitle={props.subtitle}
                                            size={1}
                                            spaced={false}
                                            className="text-center"
                                            />
                                        </Col>
                                        <Col xs={12} md="auto" className="py-3 px-4">
                                            <LinkContainer to={props.buttonPath}>
                                            <Button variant={props.buttonColor} size="lg">
                                                {props.buttonText}
                                            </Button>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                </Container>
                            </SectionPurple>
                        </>
                    : 
                        <>
                            {/* {console.log('player is logged in, and registered, rebuys are not allowed')} */}
                        </>
}
                </>
        }
    </>
  );
}

export default requireAuth(CtaSectionLoggedIn);
