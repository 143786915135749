import React from 'react'
import { ListGroupItem } from 'react-bootstrap';
import { useState } from 'react';

function ScorecardPlayerSelectRow({item, selectedPlayers, setSelectedPlayers}) {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = (e) => {
    setSelectedPlayers([...selectedPlayers, item.id])
    setIsSelected(true)
  }

  const handleUnSelect = (e) => {
    //remove item.id from selectedplayers array
    const newSelectedPlayers = selectedPlayers.filter((player) => player !== item.id)
    setSelectedPlayers(newSelectedPlayers)
    
    setIsSelected(false)
  }
  return (
    isSelected ?
        <ListGroupItem key={item.user_id} value={item.id} onClick={handleUnSelect} variant='secondary'>
          {item.users_public.name}
        </ListGroupItem>    
      :
        <ListGroupItem key={item.user_id} value={item.id} onClick={handleSelect}>
          {item.users_public.name}
        </ListGroupItem>
  )
}

export default ScorecardPlayerSelectRow