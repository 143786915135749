import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import DisplayScorecard from "../components/DisplayScorecard";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


function DisplayScorecardSection(props) {
  const router = useRouter();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />

        <Row>
          <Col>
            <DisplayScorecard  eventEntryId={router.query.evententry} />

{/* link to event */}
            <Link to={`/event?event=${router.query.event}`}>Back to Event</Link>

          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default DisplayScorecardSection;
