import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import Button from "react-bootstrap/Button";
import { useAuth } from "../util/auth";
import { useState } from "react";
import { createHoleScore,useEventDivisions,useEventSideGames,useEventName,useUsersName,useUsersPhone,createEventEntry, getLayoutHoles } from "../util/db";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Card from "react-bootstrap/Card";
import { requireAuth } from "./../util/auth";
import PromptUsersPhoneForm from "../components/PromptUsersPhoneForm";
import PromptUsersNameForm from "../components/PromptUsersNameForm";

function createScorecard (layout_id,event_entry_id){

    getLayoutHoles(layout_id).then((data) => {
        data.map(hole => {
            createHoleScore({layout_holes_id: hole.layout_holes_id,event_entries_id: event_entry_id})
        })
    })
}

function RegisterPage(props) {

  const router = useRouter()
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : undefined;

  //state
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedSideGames, setSelectedSideGames] = useState([])

  //for users name
  const {
    data: usersName = [],
    // status: usersNameStatus,
    // error: usersNameError,
  } = useUsersName(uid);

  //for users phone
  const {
    data: usersPhone = [],
    // status: usersPhoneStatus,
    // error: usersPhoneError,
  } = useUsersPhone(uid);
  
  //for event name
  const [eventName,setEventName] = useState('')

  const {
    data: eventname,
    status: eventnameStatus,
    // error: eventnameError,
  } = useEventName(router.query.id);

  //to set event name
  useEffect(() => {
      eventnameStatus !== "loading" && eventname && eventName.length > 0 && (
          setEventName(eventname[0].name)
      )
  }, [eventname,eventnameStatus,eventName.length]);

  //signupstate object
  const [signupObject,setSignupObject] = useState({
    event_divisions_id: null, 
    division_name: '', 
    division_cost: 0, 
    side_games: [], 
    side_games_cost: 0, 
    event_id: router.query.id, 
    user_id: uid,
    layout_id: null,
  })

  //get divisions
  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
  } = useEventDivisions(router.query.id);

  //get side games
  const {
    data: sideGames,
    status: sideGamesStatus,
    // error: sideGamesError
  } = useEventSideGames(router.query.id)

  //totalcharge state
  const [totalCharge,setTotalCharge] = useState(signupObject.division_cost + signupObject.side_games_cost)

  //set division
  const handleSetDivision = (event_divisions_id,division_cost,division_name,layout_id) => {
    //set division state
    setSelectedDivision(event_divisions_id)

    //set overall state object
      //copy signup object
      const newSignupObject = {...signupObject}

      //copy new division and cost to new object
      newSignupObject.event_divisions_id = event_divisions_id
      newSignupObject.division_cost = division_cost
      newSignupObject.division_name = division_name
      newSignupObject.layout_id = layout_id

      //0verwrite existing signupobject
      setSignupObject(newSignupObject)

      //update total charge state
      setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)
  }

  //set side game
  const handleSetSideGame = (new_side_game, side_game_charge) => {

    //use side game state
      //copy side games object and add new side game
      const newSelectedSideGames = [...selectedSideGames, new_side_game]

      // set state to new object
      setSelectedSideGames(newSelectedSideGames)

    //use full object
      //copy signup object
      const newSignupObject = {...signupObject}

      //add side game to array
      newSignupObject.side_games.push(new_side_game)
      newSignupObject.side_games_cost += side_game_charge

      //0verwrite existing signupobject
      setSignupObject(newSignupObject)

      //update total charge state
      setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)

  }

  // unset side game
  const handleUnsetSideGame = (remove_side_game, remove_side_game_cost) => {

    //use side game state
      const newSelectedSideGames = [...selectedSideGames]
      
      if(newSelectedSideGames.includes(remove_side_game)){
        newSelectedSideGames.splice (newSelectedSideGames.indexOf(remove_side_game), 1)
      }

      setSelectedSideGames(newSelectedSideGames)

    //use full object
      //copy signup object
      const newSignupObject = {...signupObject}

      //remove side game from array
      const index = newSignupObject.side_games.indexOf(remove_side_game);
      if (index > -1) {
        newSignupObject.side_games.splice(index, 1);
      }

      //remove cost of side game from charge
      newSignupObject.side_games_cost -= remove_side_game_cost

      //0verwrite existing signupobject
      setSignupObject(newSignupObject)

      //update total charge state
      setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)
  }

  //return true if this is the selected division
  const isSelectedDivision = (this_event_divisions_id) => {
    if(selectedDivision === this_event_divisions_id){
      return true
    }else{
      return false
    }
  }

  //return true if this is the selected sidegame
  const isSelectedSideGame = (this_sidegame_id) => {
    if(selectedSideGames.includes(this_sidegame_id)){
      return true
    }else{
      return false
    }
  }
  
  const handleRegisterClick = () => {

    if(signupObject.event_divisions_id === null){
      alert('Please select a division')

    }else{

      const registerObject = {       
          event_id: signupObject.event_id,
          user_id: signupObject.user_id,
          event_divisions_id: signupObject.event_divisions_id,
      }

      //check to see if rebuys are allowed?
      //check to see if player is already registered
      
      //create event entry
      createEventEntry(registerObject).then((data) => {
          // createScorecard(signupObject.layout_id,data[0].id)
          //createscorecard disabled. create scorecard in scorecard component
          router.push(`/event?event=${router.query.id}`)
      })
    }

  }

  //error handling
//   function ErrorHandler({error}) {
//     return (
//       <div role="alert">
//         <p>An error occurred:</p>
//         <pre>{error.message}</pre>
//       </div>
//     )
//   }
  
//   function Player({name}) {
//     try {
//       return <div>Hello, {name.toUpperCase()}</div>
//     } catch (error) {
//       return <ErrorHandler error={error} />
//     }
//   }

  function checkForName(){
    if(usersName.length === 0){
      return false
    }else{
      return true
    }
  }

  function checkForPhone(){
    if(usersPhone.length === 0 ){
      return false
    }else{
      // console.log(usersPhone[0])
      if(usersPhone[0].phonenumber == null){
        return false
      }else{
        return true
      }
    }
  }

  //useeffect to make totalcharge work
  useEffect(() => {
    // Update the document title using the browser API
    const $thistotalcost = signupObject.division_cost + signupObject.side_games_cost
    setTotalCharge("Total Charge: $" +  $thistotalcost )
  },[signupObject]);


  return (
    <>
      <Meta title="Signup" />

      <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={eventName}
          subtitle={totalCharge}
          size={1}
          spaced={true}
          className="text-center"
        />
      </Container>
      </Section>

      {
        // checkForName() 
        usersName.length !== 0
          ? 
            <center><h6>Player: {usersName[0].name}</h6></center> 
          : 
            <PromptUsersNameForm uid={uid} />
      }

      {/* {checkForPhone() ? <center><h6>Phone Number: {usersPhone[0].phonenumber} </h6></center> : <PromptUsersPhoneForm uid={uid} />} */}

      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >

      <Container>
        <SectionHeader
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">

          {itemsStatus !== "loading" && items && items.length > 0 && (
            items.map((item, index) => (
              <Col
                md={12}
                lg={4}
                className="py-3 d-flex align-items-stretch"
                key={index}
              >

              {isSelectedDivision(item.event_divisions_id) ? <Card className="w-100 " style={{background:'#35155D'}}>
                <Card.Body className="d-flex flex-column p-4">
                  <h5 className="font-weight-bold mb-4" style={{color:'white'}}>
                    {item.divisions.name}
                  </h5>
                  <h1 className="font-weight-bold mb-3" style={{color:'white'}}>
                    ${item.division_cost}
                  </h1>
                  <Button
                    variant="primary"
                    size="lg"
                    block={true}
                    className="mt-auto"
                    disabled
                  >
                    Selected
                  </Button>
                </Card.Body>
              </Card>
              : <Card className="w-100 ">
                <Card.Body className="d-flex flex-column p-4">
                  <h5 className="font-weight-bold mb-4">{item.divisions.name}</h5>
                  <h1 className="font-weight-bold mb-3">
                    ${item.division_cost}
                  </h1>
                  <Button
                    variant="primary"
                    size="lg"
                    block={true}
                    className="mt-auto"
                    onClick={() => handleSetDivision(item.event_divisions_id,item.division_cost,item.divisions.name,item.layout_id)}
                  >
                    Select
                  </Button>
                </Card.Body>
              </Card>
              }
            </Col>
          ))
        )}
        </Row>
      </Container>
    </Section>

    {/* side game section */}
    <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
      <Container>
        <SectionHeader
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {sideGamesStatus !== "loading" && sideGames && sideGames.length > 0 && (
            sideGames.map((sideGame, index) => (
              <Col
                md={12}
                lg={4}
                className="py-3 d-flex align-items-stretch"
                key={index}
              >

              {isSelectedSideGame(sideGame.id) ? <Card className="w-100 ">
                  <Card.Body className="d-flex flex-column p-4 bg-success">
                    
                    <h5 className="font-weight-bold mb-4">{sideGame.side_game_name}</h5>
                    <h1 className="font-weight-bold mb-3">
                      ${sideGame.side_game_price}
                    </h1>
                    <Button
                      variant="primary"
                      size="lg"
                      block={true}
                      className="mt-auto"
                      onClick={() => handleUnsetSideGame(sideGame.id,sideGame.side_game_price)}
                    >
                      Unselect
                    </Button>
                  </Card.Body>
                </Card>
              :   <Card className="w-100 ">
              <Card.Body className="d-flex flex-column p-4">
                
                <h5 className="font-weight-bold mb-4">{sideGame.side_game_name}</h5>
                <h1 className="font-weight-bold mb-3">
                  ${sideGame.side_game_price}
                </h1>
                <Button
                  variant="primary"
                  size="lg"
                  block={true}
                  className="mt-auto"
                   onClick={() => handleSetSideGame(sideGame.id, sideGame.side_game_price)}
                >
                  Select 
                </Button>
              </Card.Body>
            </Card>
              }
            </Col>
          ))
        )}
        </Row>
      </Container>
    </Section>

    <h5 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>{totalCharge}</h5>

    <Container style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <Row>
        <Col lg={6} className="mt-4 mt-lg-0">
          <Button variant={props.buttonColor} size="lg" onClick={() => {
            handleRegisterClick()
          }}>
            Register!
          </Button>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default requireAuth(RegisterPage);
