import React from 'react'
import ScorecardRow from './ScorecardRow'
import Table from 'react-bootstrap/Table'
import { useRouter } from './../util/router.js'

const ScorecardTable = ({players, currentHole, onUpdateHoleScore,isComplete,scorecardId}) => {


  return (
    <Table>
      <thead>
        <tr>    
            <th>Player</th>
            <th></th>
            <th className='d-flex justify-content-center'>Score</th>
            <th>
            </th>
        </tr>
      </thead>
      <tbody>
        {players.map((player, index) => 
          <ScorecardRow scorecardId={scorecardId} isComplete={isComplete} key={index} player={player} currentHole={currentHole} onUpdateHoleScore={onUpdateHoleScore}/>
        )}
      </tbody>
    </Table>
  )
}

export default ScorecardTable