import React from 'react'

function ScorecardSettings() {
  return (
    <div>
        Settings Menu
    </div>
  )
}

export default ScorecardSettings