import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import { useUsersName } from "../util/db";
import Container from "react-bootstrap/Container";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import PlayersEvents from "../components/PlayersEvents";
// import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PlayerPage(props) {

    const router = useRouter()
    const uid = router.query.id

    function checkForName(){
        if(usersName.length === 0){
          return false
        }else{
          return true
        }
      }

    //for users name
    const {
        data: usersName = [],
        // status: usersNameStatus,
        // error: usersNameError,
    } = useUsersName(uid);

  return (
    <>
      <Meta title="Player" />

      <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
        //   title="Player"
        //   subtitle={totalCharge}
          size={1}
          spaced={true}
          className="text-center"
        />
        {checkForName() ? <center><h1>{usersName[0].name}</h1></center> : <></>}      
      </Container>
      </Section>

      {/* {checkForName() ? <center><h6>Player: {usersName[0].name}</h6></center> : <PromptUsersName />} */}

      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <Row>
            <Col>
        {/* <Card>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
            style={{background:"#35155D", text:"white"}}
          >
            <Card.Title style={{color:"#ffffff"}}>
              Events
            </Card.Title>
          </Card.Header>
          <Card.Body> */}
        <PlayersEvents uid={uid}/>
        {/* </Card.Body>
        </Card> */}
        </Col>
        </Row>
        </Container>

    </Section>
    </>
  );
}

export default PlayerPage;
