import React from 'react'
import { useGroupScorecards } from '../util/db'
import EventManageCardsCard from './EventManageCardsCard'
import { CardGroup, Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function EventManageCards({eventId,uid}) {
    const {
        data:cards,
        status:cardsStatus,
        error:cardsError
    } = useGroupScorecards(eventId)
    
    return (
        <div>
            {cardsStatus === 'loading' && (
                <Spinner animation="border" role="status" />
            )}
            {cardsStatus === 'error' && (
                <div>Error: {cardsError.message}</div>
            )}
            {cardsStatus !== "loading" && cards && cards.length > 0 && (
                <Row>
                    {cards.map((card, index) => (
                        <Col key={index}>
                            <EventManageCardsCard uid={uid} card={card} />
                        </Col>
                    ))}
                </Row>
            )}
            {cardsStatus !== "loading" && cards && cards.length === 0 && (
                <Container>No cards yet</Container>
            )}

        </div>
    )
}

export default EventManageCards