import React from 'react'
import EventCompleteRoundTd from './EventCompleteRoundTd'
import EventCompleteRoundFalseTd from './EventCompleteRoundFalseTd'
import EventCalculatePayoutsTd from './EventCalculatePayoutsTd'
import { Row,Col } from 'react-bootstrap'
import { Container } from 'react-bootstrap'

function EventManageComplete(props) {
  return (
    <Container>
      <Row>
        <Col lg={6}>
          {
            props.isEventComplete 
              ? 
                <EventCompleteRoundFalseTd 
                  eventId={props.eventId} 
                  isEventComplete={props.isEventComplete}
                /> 
              : 
                <EventCompleteRoundTd 
                  eventId={props.eventId} 
                  isEventComplete={props.isEventComplete}
                />
          }
        </Col>
        <Col lg={6}>
            {<EventCalculatePayoutsTd eventId={props.eventId}/>}
        </Col>
      </Row>
    </Container>
  )
}

export default EventManageComplete