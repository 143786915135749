import React from "react";
import EventManageTools from "../pages/EventManagePage";
import { Link, useRouter } from "./../util/router.js";

function EventTdSection(props) {
  const eventId = props.eventId;

      {/* <EventManageTools eventId={props.eventId} scoringOrderGolf={props.scoringOrderGolf} isEventComplete={props.isEventComplete}/> */}


      // scoringOrderGolf={scoringOrderGolf} eventId={eventId} isEventComplete={isEventComplete}
  return (
    <div className="text-right">
      
      <Link to={`/event_manage?event=${eventId}`}>
        Manage Event⚙️
      </Link>
    </div>
  );
}

export default EventTdSection;
