import React, { useEffect } from 'react'
import { useState } from 'react';
import { insertScoreEdit, updateHoleScore } from '../util/db';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router';
import Badge from 'react-bootstrap/Badge'
import Toast from 'react-bootstrap/Toast'

function ScorecardRow({player, currentHole, onUpdateHoleScore, isComplete,scorecardId}) {
  const router = useRouter()
  const defaultScore = 3
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  // Returned status value will be "idle" if we're waiting on
  // the uid value or "loading" if the query is executing.
  const uid = auth.user ? auth.user.uid : undefined;

  function onViewEdits() {
    
    //get id of hole_score_id to feed to holescoreedits
    
    //redirect to /holescoreedits
    router.push(`/holescoreedits?hole=${hole_scores_id}&scorecard=${scorecardId}`)
    
  }

  //function to log hole_score_edit into databse
  function logHoleScoreEdit(hole_score_id,newScore,uid) {
    // console.log('logging hole score edit',hole_score_id,newScore,uid)
    insertScoreEdit({hole_scores_id:hole_score_id,score:newScore,editor_id: uid})
  }

  function onClickLockedScore() {
    //alert user that the score is locked and edits are disabled
    alert('This scorecard is locked. Please contact the event organizer to make changes.')
  }

  function getHolescoreId() {
    let hole_scores_id = null
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id
      }
      return hole_scores_id
    })  
    return hole_scores_id
  }

  function onLeftScore() {
    let newScore = null

    if(thisScore === undefined || thisScore === null){
      // console.log('score was null. Set to default')
      newScore = defaultScore-1
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else if (thisScore > 0) {
      // console.log('score was greater than 0. Allow decrement')
      newScore = thisScore - 1
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  function onRightScore() {
    let newScore = null

    if(thisScore === undefined || thisScore === null){
      // console.log('score was null. Set to default')
      newScore = defaultScore+1
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else{
      newScore = thisScore + 1
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  function onClickScore() {
    if(thisScore === undefined || thisScore === null){
      let newScore = defaultScore
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else{
      let newScore = null
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore})
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  const [thisScore, setThisScore] = useState()

  useEffect(() => {
    function getHolescore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
      return this_hole_score[0].score
      }
    }
    
    //if player.event_entries.holes_scores is not empty
    if(player.event_entries.hole_scores.length !== 0){
      setThisScore(getHolescore())  
    }

  }, [currentHole, player.event_entries.hole_scores])

  const event_entries_id = player.event_entries_id
  const hole_scores_id=getHolescoreId()

  // console.log('player',player)

  return (
    <>
    <tr>
      <td>
        {player.event_entries.users_public.name ? player.event_entries.users_public.name : ''}


        <span onClick={toggleHoleInfo} className="mb-2 ml-2">
          ℹ️
        </span>

        <Badge onClick={onViewEdits} style={{cursor:'pointer',float:'right'}}>
          {/* <sup>✏️</sup> */}
          ✏️
        </Badge>
      </td>

      {isComplete
        ?
          <td 
            className='d-flex justify-content-center border border-primary align-items-center' style={{background:'grey'}}
            onClick={()=>onClickLockedScore()}
          >
            -
          </td>
        :
          <td 
            onClick={ ()=>onLeftScore()} 
            className='d-flex justify-content-center border border-primary align-items-center' style={{background:'#8CABFF'}}
          >
            -
          </td>
      }
        
      {isComplete
        ?
          <td className='' onClick={()=>onClickLockedScore()}>
            <div className='d-flex justify-content-center'>
              {thisScore}
            </div>
          </td>
        :
          <td className='' onClick={()=>onClickScore()}>
            <div className='d-flex justify-content-center'>
              {thisScore}
            </div>
          </td>
      }
      
      {isComplete 
        ?
          <td className='d-flex justify-content-center border border-primary align-items-center' style={{background:'grey'}}
            onClick={()=>onClickLockedScore()}
          >
            +
          </td>
        :
          <td onClick={onRightScore} className='d-flex justify-content-center border border-primary align-items-center' style={{background:'#8CABFF'}}>
            +
          </td>
      }
        
    </tr>
    <tr>
      <td>
      <Toast delay={3000} autohide show={showHoleInfo} onClose={toggleHoleInfo}>
          <Toast.Header>
            {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            /> */}
            {player.event_entries.hole_scores.map((hole_score, index) => {
              if (hole_score.layout_holes.hole_order === currentHole) {
                return (
                  <div key={index}>
                    <strong className="me-auto">Hole {hole_score.layout_holes.holes.hole_name}</strong>
                  </div>
                )
              }
              return (
                null
              )
            })}
          </Toast.Header>
          <Toast.Body>
            {player.event_entries.hole_scores.map((hole_score, index) => {
              if (hole_score.layout_holes.hole_order === currentHole) {
                return (
                  <div key={index}>
                    {hole_score.layout_holes.holes.hole_description ? hole_score.layout_holes.holes.hole_description : 'No information available'}
                  </div>
                )
              }
              return (
                null
              )
            })}
          </Toast.Body>
        </Toast>
      </td>
    </tr>
    </>
  )
}

export default ScorecardRow