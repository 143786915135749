import { getHoleScoreEdits, useHoleScoreEdits } from '../util/db';
import { useRouter } from "../util/router";
import Spinner from "react-bootstrap/Spinner";

function HoleScoreEdits() {
    

    const router = useRouter()
    const {
        data: items,
        status: itemsStatus,
        error: itemsError,
      } = useHoleScoreEdits(router.query.hole);
      

    return (
        <div>
            <h1  style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                Hole Score Edits
            </h1>
        {itemsStatus !== "loading" && items && items.length > 0 && (

            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Score</th>
                        <th scope="col">Editor</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(function(item) {
                        return (
                            <tr key={item.id}>
                                <td>{item.created_at}</td>
                                <td>{item.score}</td>
                                <td>{item.users_public.name}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        )}

        {itemsStatus === "loading" && (
            <div className="py-5 px-3 align-self-center">
                <Spinner animation="border" variant="primary" />
            </div>
        )}

        {items !== undefined && items.length === 0 && (
            
                
                    <div className="py-5 px-3 align-self-center">
                        <p className='text-center'>No edits have been made to this hole.</p>
                    </div>   
        )}

            {/* //link back to scorecard page */}
            <div className="py-5 px-3 align-self-center">
                <a href={'/scorecard?scorecard='+router.query.scorecard}>Back to Scorecard</a>
            </div>

        </div>
    );
}

export default HoleScoreEdits;