import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { usePlayersByEvent,updateEventCompleteTrue } from "./../util/db";
import { Form } from "react-bootstrap";

function EventCompleteRoundTd(props) {
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersByEvent(props.eventId);

  const [pending, setPending] = useState(false);
  const itemsAreEmpty = !items || items.length === 0;

  const onSubmit = (e) => {
    e.preventDefault();

    // Show pending indicator
    setPending(true);
    updateEventCompleteTrue(props.eventId)
        
    setPending(false)
  };

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      <Card>
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
          style={{background:"#35155D", text:"white"}}
        >
          <Card.Title style={{color:"#ffffff"}}>
            Finalize Event
          </Card.Title>     
        </Card.Header>

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="py-5 px-3 align-self-center">
            {itemsStatus === "loading" && (
              <Spinner animation="border" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {itemsStatus !== "loading" && itemsAreEmpty && (
              <>No players yet</>
            )}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
            <Form onSubmit={onSubmit}>
              <Form.Group controlId="formUserId">
                <Form.Control type="hidden" defaultValue={props.eventId} />
              </Form.Group>
              <Button
                variant="primary"
                block={true}
                size="lg"
                type="submit"
                // disabled={pending}
              >Finalize
                {pending && (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden={true}
                    className="ml-2"
                  >
                    <span className="sr-only">Sending...</span>
                  </Spinner>
                )}
              </Button>
            </Form>
          </>
        )}
      </Card>
      <hr />
    </>
  );
}

export default EventCompleteRoundTd;
