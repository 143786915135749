import React from "react";
import "./FeaturesSection.scss";
import { useItems } from "../util/db";
import FormAlert from "./FormAlert";
import { Link } from "../util/router";

function EventsSection(props) {

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItems();

  return (
    <>
        {itemsError && (
          <div className="mb-3">
            <FormAlert type="error" message={itemsError.message} />
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <>
            <table className='table text-center'>
              <tbody>
                {items.map((item, index) => (
                  <tr key={item.id}>
                      <td>
                        {item.id}
                      </td>
                      <td>
                        <Link to={`/event?event=${item.id}`}>{item.name}</Link>
                      </td>
                      <td>
                          {item.date}
                      </td>
                      {/* <td> */}

                          {/* <LinkContainer
                            to={
                              auth.user
                                ? `/signup?id=${item.id}`
                                // : `/auth/signup?next=/signup?id=${item.id}`
                                : `/auth/signup?next=/dashboard`
                            }
                          >
                          
                            <Button variant={props.buttonColor} size="lg" >
                              Signup!
                            </Button>
                          </LinkContainer> */}

                          {/* <LinkContainer
                            to={
                              auth.user
                                ? `/register?id=${item.id}`
                                // : `/auth/signup?next=/signup?id=${item.id}`
                                : `/auth/register?next=/dashboard`
                            }
                          >
                          
                            <Button variant={props.buttonColor} size="lg" >
                              Register!
                            </Button>
                          </LinkContainer> */}



                            {/* causes error */}
                            {/* <Button variant={props.buttonColor} size="lg" onClick={() => {
                              try {
                                router.push('/signup', { item })
                                } catch (e) {
                                console.log(e)
                                }
                            }}>
                              Signup2!
                            </Button> */}

                      {/* </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </>
  );
}

export default EventsSection;