import { usePlayersByEventDivision } from "../util/db"
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Table from "react-bootstrap/Table";

function EventDivisionPlayers (props) {

    const {
        data: items,
        status: itemsStatus,
        error: itemsError,
        } = usePlayersByEventDivision(props.eventId,props.event_divisions_id);

      const itemsAreEmpty = !items || items.length === 0;

    //   console.log(items)
    itemsError && console.log(itemsError, 'error')

    return (
        <>
            {(itemsStatus === "loading" || itemsAreEmpty) && (
                <div className="py-5 px-3 align-self-center">
                    {itemsStatus === "loading" && (
                        <Spinner animation="border" variant="primary">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                    )}

                    {itemsStatus !== "loading" && itemsAreEmpty && (
                        <>No players yet</>
                    )}
                </div>
            )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <Table striped bordered hover>
                <thead style={{background:"#512B81"}}>
                    <tr>
                        <td style={{color:"#ffffff"}}>Name</td>
                        <td style={{color:"#ffffff"}}>Score</td>
                        {/* if event is complete, display points/payouts */}
                        {props.isEventComplete && 
                        <td style={{color:"#ffffff"}}>Payout</td>}
                        {props.isEventComplete && 
                        <td style={{color:"#ffffff"}}>Points</td>}
                    </tr>
                </thead>
                <tbody style={{background:"#ffffff"}}>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <Link to={`/player?id=${item.user_id}`}>{item.users_public.name}</Link>
                            </td>
                            { 
                                item.is_dnf === true 
                                ?
                                    <td>DNF</td>
                                :
                                    <td>
                                        {
                                            !item.is_dropped 
                                            ?
                                                <>
                                                {item.score}

                                                {/* //link to display_scorecard */}
                                                {item.score !== null ?
                                                    <>
                                                    
                                                    <Link to={`/display_scorecard?evententry=${item.id}&event=${props.eventId}`}>
                                                        📇
                                                    </Link>
                                                    </>
                                                    :
                                                    <>
                                                    {item.group_scorecard_players.length !== 0
                                                     ?
                                                     '🥏'
                                                     :
                                                        null}
                                                </>
                                                }
                                                </>
                                            : 
                                            <s>{item.score}</s>
                                        }
                                    </td>
                            }                            
                            {props.isEventComplete  ?
                                    !item.payouts || item.payouts.length === 0 ?
                                        <td></td>//event is complete. no payout
                                        :
                                        <td>${item.payouts[0].payout_amount?item.payouts[0].payout_amount:<></>}</td> 
                                        //event is complete. payout
                                    :
                                    null//event is not complete
                            }                         

                            {props.isEventComplete ? 
                                <td>{item.points}</td> 
                                :null}
                        </tr>
                    ))}
                </tbody>
            </Table>
          )}
        </>
    )
}

export default EventDivisionPlayers