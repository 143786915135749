import React, { useEffect } from 'react'
import Table from "react-bootstrap/Table";
import ScorecardSummaryRow from './ScorecardSummaryRow';
import { Alert, Button } from 'react-bootstrap';
import { useState } from 'react';

function ScorecardSummary({players,maxHole,onSubmitScorecard,isComplete,onJumpToHole}) {

  const [emptyHoles, setEmptyHoles] = useState(checkForEmptyHoles())

  //array to store integers
  let holes = []
  for(let i=0;i<maxHole;i++){
    holes.push(i+1)
  }

  //function to check whether there are any empty holes_scores.score in players array
  function checkForEmptyHoles(){
    let emptyHoles = false
    players.forEach((player, index) => {
      player.event_entries.hole_scores.forEach((score, index) => {
        if(score.score === null){
          emptyHoles = true
        }
      })
    })
    return emptyHoles
  }

  return (
    <>
    <Table responsive style={{ width: "100%", tableLayout: "auto", border: "2px solid #35155D",background:"#8CABFF" }}>
        <thead style={{background:"#35155D", text:"white"}}>
            <tr>
                <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D" }}></th>
                {holes.map((hole, index) => (
                    <th onClick={()=>onJumpToHole(index+1)} key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"white" }}>
                      {hole}
                    </th>
                ))}
                <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"white" }}>Total</th>
            </tr>
        </thead>
        <tbody>
            {players.map((player, index) => (
              <ScorecardSummaryRow player={player} key={index}/>
            ))}
        </tbody>
    </Table>
    <br/>
      
      <div className="text-center">
      {isComplete===false &&
          <>
            {emptyHoles &&
              <Alert variant='danger'>
                Please enter a score for all holes before submitting. If a player DNF (did not finish), please leave score blank
              </Alert>
            }
            <Button style={{background:'#35155D'}} type="submit" size='lg' onClick={onSubmitScorecard}>
              Submit
            </Button>
          </>
        }
      </div>
      </>

  )
}
export default ScorecardSummary