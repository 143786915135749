import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import "./DashboardItems.scss";
import { useRouter } from "../util/router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import ScorecardCreateComponent from "./ScorecardCreateComponent";
import { useAuth } from "../util/auth";
import { useEntriesByEventPlayerScorecard } from "../util/db";

function ScorecardBeginComponent(props) {
  const router = useRouter();
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : undefined;
  const eventId = props.eventId;

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEntriesByEventPlayerScorecard(eventId, uid);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {!itemsAreEmpty && (
        <Section
          bg={props.bg}
          textColor={props.textColor}
          size={props.size}
          bgImage={props.bgImage}
          bgImageOpacity={props.bgImageOpacity}
        >
          <Container>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
              className="text-center"
          />
            <Row>
              <Col>
                {(itemsStatus === "loading" || itemsAreEmpty) && (
                  <div className="py-5 px-3 align-self-center">
                    {itemsStatus === "loading" && (
                      <Spinner animation="border" variant="primary">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}

                    {itemsStatus !== "loading" && itemsAreEmpty && (
                      <>
                      ...
                      </>
                    )}
                  </div>
                )}
                                      
                {itemsStatus !== "loading" && items && items.length > 0 && (
                  items.map((item, index) => (
                    <Card key={index} className="mb-2" border="primary">
                      <Card.Header
                        as="h5"
                        className="d-flex justify-content-between align-items-center"
                        style={{background:"#35155D", text:"white"}}
                      >
                        <Card.Title style={{color:"#ffffff"}}>
                          Scorecard
                        </Card.Title>
                      </Card.Header>
                      
                      <Card.Body className="d-flex justify-content-center align-items-center" style={{background:"#8CABFF"}}>
                        {item.group_scorecard_players && item.group_scorecard_players.length > 0
                          ?
                            <>
                              {item.group_scorecard_players[0].group_scorecard.is_complete === false
                                ?
                                  <Button
                                  variant="primary"
                                  onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
                                  >
                                    Resume Scorecard
                                  </Button>
                                : 
                                  <Button
                                  variant="primary"
                                  onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
                                  >
                                    View Scorecard
                                  </Button>
                              }
                            </>
                          :
                            <>
                              <ScorecardCreateComponent eventEntryId={item.id} eventId={props.eventId} />
                            </>
                        }
                      </Card.Body>  
                    </Card>
                  ))
                )}    
              </Col>
            </Row>
          </Container>
        </Section>
      )}
    </>
  )
}

export default ScorecardBeginComponent;
