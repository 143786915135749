import React from 'react'
import { useState } from 'react';
import { Form, Button, Spinner } from "react-bootstrap";
import { updateScore, updateDnf, updateIsDroppedFalse, updatePointsNull, updatePlayoffWinnerNull } from "./../util/db";
import ConfirmDeletePlayerModal from "./ConfirmDeletePlayerModal";
import { Link } from "react-router-dom";

function EventManagePlayersRow({player,eventId}) {

  function onScore(e) {
    e.preventDefault();
    setScorePending(true);
    
    //update state
    setScore(score)

    //update score in db
    updateDnf(player.id,{is_dnf:false},eventId)
    updateScore(player.id,{score:score},eventId)

    setScorePending(false);
  }

  function onClear(e) {
    e.preventDefault();
    setClearPending(true);

      //set score to null
      setScore(null)

      // set player as not dnf
      updateDnf(player.id,{is_dnf:false},eventId)
      updateScore(player.id,{score:null},eventId)

      //set dropped to false
      updateIsDroppedFalse(player.id)

      //set points to null
      updatePointsNull(player.id)

      //set playoff winnner to null
      updatePlayoffWinnerNull(player.id)

      //remove hole scores
      // deleteHoleScores(player.id).then(
      //     console.log("scorecard unscored")  
      // )

      setClearPending(false)
  }

  function onDnf(e) {
    e.preventDefault();
    
    setScore('DNF')

    // Show pending indicator
    setDNFPending(true);

    //update score in db
    updateDnf(player.id,{is_dnf:true},eventId)
    updateScore(player.id,{score:null},eventId)

    setDNFPending(false)
  }

  const [score, setScore] = useState(player.score);
  const [scorePending, setScorePending] = useState(false);
  const [dnfPending, setDNFPending] = useState(false);
  const [clearPending, setClearPending] = useState(false);

  return (
    <tr style={{backgroundColor:'#8CABFF',border: '2px solid #35155D'}}>
      <td style={{verticalAlign:'middle'}}>
      <Link to={`/player?id=${player.user_id}`} style={{color:'black'}}>
          {player.users_public.name}
          </Link>
      </td>
      <td style={{verticalAlign:'middle'}}>
        {scorePending 
          ? 
            <Button style={{backgroundColor: '#512B81'}}>
              <Spinner animation="border" role="status">
              </Spinner>
            </Button>
          :
            <Form>
              <Form.Group className='mt-3'>
                <Form.Control 
                  type="text" 
                  onChange={(e) => setScore(e.target.value)}
                  value={
                      player.is_dnf === true
                        ?
                          'DNF'
                        :
                          score === null 
                            ? 
                              '' 
                            : 
                              score
                  }
                />
              </Form.Group>
            </Form>
        }
      </td>
      <td style={{verticalAlign:'middle'}}>
        <Button style={{backgroundColor: '#512B81'}} onClick={onScore}>Score</Button>
      </td>
      <td style={{verticalAlign:'middle'}}>
        {clearPending ? 
          <Button variant='outline-secondary'>
            <Spinner animation="border" role="status">
            </Spinner>
          </Button>
        :
          <Button style={{backgroundColor: '#512B81'}} onClick={onClear}>Clear</Button>
        }
        
      </td>
      <td style={{verticalAlign:'middle'}}>
        {dnfPending ? 
        <Button style={{backgroundColor: '#512B81'}}>
         <Spinner animation="border" role="status">
           
          </Spinner>
        </Button>
        :
        <Button style={{backgroundColor: '#512B81'}} onClick={onDnf}>
          DNF
        </Button>        
        }

      </td>
      <td style={{verticalAlign:'middle'}}>
        <ConfirmDeletePlayerModal 
            heading={"Remove Player?"} 
            bodyText={"Are you sure you want to remove this player from the event?"} 
            confirmText={"Yes"} 
            cancelText={"Cancel"} 
            buttonText={"🗑️"} 
            eventEntryId={player.id}
            eventId={eventId}
            eventDivisionsId={player.event_divisions_id}
        />
      </td>
    </tr>
  )
}

export default EventManagePlayersRow