import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import FormAlert from "./FormAlert";
import { useAuth } from "./../util/auth";
import { useEventsByTd } from "./../util/db";
import "./DashboardItems.scss";
import { Link } from "react-router-dom";

function DashboardItemsTd(props) {
  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEventsByTd(auth.user.uid);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

        {!itemsAreEmpty && (
        <Card key={props.hole_score_id}>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
            style={{background:"#35155D", text:"white"}}
          >
            <Card.Title style={{color:"#ffffff"}}>
              Events (TD)
            </Card.Title>
          </Card.Header>

          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>No events yet</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <ListGroup variant="flush">
              {items.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className={
                    `d-flex justify-content-between align-items-center` +
                    (item.featured ? " featured" : "")
                  }
                >
                  <Link to={`/event?event=${item.id}`}>
                  
                  {item.name}
                  </Link>
                  {/* link to manage event */}
                  <Link to={`/event_manage?event=${item.id}`}>

                  {/* <span className="badge badge-primary badge-pill"> */}
                    ⚙️
                  {/* </span> */}
                  </Link>

                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
        )}
    </>
  );
}

export default DashboardItemsTd;
