import { usePlayerNameByEntryId, useScorecard } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useState } from 'react';
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useScoreboard } from "../util/db";
import { useEventEntriesScoreboard } from "../util/db";
import { useEventEntries } from "../util/db";


function DisplayScoreboard(props) {

    const eventId = props.eventId
    // const [totalScore, setTotalScore] = useState(0);

    // get scorecard for this evententryid
    const {
        data: scoreBoard,
        status: scoreBoardStatus,
        error: scoreBoardError,
    } = useEventEntriesScoreboard(eventId);

    //get event_entries for this event
    // const {
    //     data: eventEntries,
    //     status: eventEntriesStatus,
    //     error: eventEntriesError,
    // } = useEventEntries(eventId);

    console.log(scoreBoard)
    // console.log(eventEntries)

    // if( eventEntriesStatus !== "loading" && eventEntries !== undefined && eventEntries.length > 0) {
    //     console.log("eventEntries=",eventEntries)

    //     // loop through and get all hole scores for these event entries
    //     eventEntries.forEach((eventEntry) => {
    //         console.log("eventEntry=",eventEntry)
    //         const {
    //             data: scoreBoard,
    //             status: scoreBoardStatus,
    //             error: scoreBoardError,
    //         } = useScoreboard(eventEntry.event_entries_id);

    //         if( scoreBoardStatus !== "loading" && scoreBoard !== undefined && scoreBoard.length > 0) {
    //             console.log("scoreBoard=",scoreBoard)
    //         }
    //     }
    //     )
    // }


    // get player name
    // const {  
    //     data: playerName, 
    //     status: playerNameStatus, 
    //     error: playerNameError, 
    // } = usePlayerNameByEntryId(eventEntryId);

    // let playersName = "";
    // if(playerNameStatus !== "loading" && playerName !== undefined && playerName.length > 0) {
    //     playersName=playerName[0].users_public.name
    // }


    // if(scoreBoardStatus !== "loading" && scoreBoard !== undefined && scoreBoard.length > 0) {
    //     const result = scoreBoard.reduce((total, currentValue) => total = total + currentValue.score,0);
    //     if(totalScore !== result){
    //       setTotalScore(result)
    //     }
    //   }

    return (
        <>
            <Section
                bg={props.bg}
                textColor={props.textColor}
                size={props.size}
                bgImage={props.bgImage}
                bgImageOpacity={props.bgImageOpacity}
            >

      <Container>
        <SectionHeader
        //   title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
            <Row>
                <Col>
                    {/* {scoreBoardStatus === "loading" && (
                        <div className="py-5 px-3 align-self-center">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}

                    {scoreBoardStatus === "error" && (
                        <div className="py-5 px-3 align-self-center">
                            <FormAlert type="error" message={scoreBoardError.message} />
                        </div>
                    )}

                    {scoreBoardStatus === "success" && scoreBoard && scoreBoard.length > 0 && ( */}

                        <>


                            {/* <h1>Scoreboard</h1>
                            <Table striped="columns" responsive style={{ width: "auto", tableLayout: "auto", border: "2px solid forestgreen" }}>
                                <thead>
                                    <tr>
                                        <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid forestgreen" }}></th>
                                        
                                            {scoreBoard.map((item) => (
                                                <th key={item.hole_scores_id} style={{ width: "auto", tableLayout: "auto", border: "2px solid forestgreen" }}>
                                                    <p className="align-bottom">
                                                        {item.layout_holes.hole_order}
                                                    </p>
                                                </th>
                                            ))}
                                            <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid forestgreen" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "auto", tableLayout: "auto", border: "2px solid forestgreen", }}>
                                            
                                        </td>
                                            {scoreBoard.map((item) => (
                                                <td key={item.hole_scores_id} className="align-bottom" style={{ width: "auto", tableLayout: "auto", border: "2px solid forestgreen" }}>
                                                    <p>
                                                        <Link to={`/holescoreedits?id=${item.hole_scores_id}&event_entries_id=${props.eventEntryId}`}>{item.score}</Link>
                                                    </p>
                                                </td>
                                            ))}
                                            <td className="align-middle text-center" style={{ width: "auto", tableLayout: "auto", border: "2px solid forestgreen", }}>
                                            
                                            </td>
                                    </tr>
                                </tbody>
                            </Table> */}
                        
                        <br />
                        </>
                    {/* )} */}
                    </Col>
                </Row>
            </Container>
            </Section>
        </>
    );
}

export default DisplayScoreboard;