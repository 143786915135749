import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import EventSection from "../components/EventSection";
import EventSideGameSection from "../components/EventSideGameSection";
import { useAuth } from "../util/auth";
import { getScoringOrderGolf, useEntriesByEventPlayer, useEventEntriesByEventUser, useEventName, useEventTdId } from "../util/db";
import EventTdSection from "../components/EventTdSection";
import ScorecardBeginComponent from "../components/ScorecardBeginComponent";
import CtaSection from "../components/CtaSection";
import CtaSectionLoggedIn from "../components/CtaSectionLoggedIn";
import DisplayScoreboard from "../components/DisplayScoreboard";
import { Button } from "react-bootstrap";
import SectionHeader from "../components/SectionHeader";

function EventPage(props) {

    const router = useRouter()
    const auth = useAuth();

    const eventId = router.query.event
    const uid = auth.user ? auth.user.uid : '';
    const [isEventComplete,setIsEventComplete] = useState(false)
    const [eventName,setEventName] = useState('')
    const [eventDate,setEventDate]  = useState()
    const [scoringOrderGolf,setScoringOrderGolf] = useState(null)
    const [tdId,setTdId] = useState('')
    const [isLoggedIn,setIsLoggedIn] = useState(false)


    // let scoringOrderGolf = null

    // getScoringOrderGolf(eventId).then((result) => {
    //     // console.log(result.scoring_order_golf, 'result')
    //     scoringOrderGolf = result.scoring_order_golf
    //     // console.log(scoringOrderGolf, 'scoringOrderGolf')
    // })
    
    useEffect(() => {
        if(uid !== ''){
            setIsLoggedIn(true)
        }else{
            setIsLoggedIn(false)
        }
    }, [uid])

    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useEventName(eventId);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setEventName(items[0].name)

        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (

            setEventDate(items[0].date)
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setScoringOrderGolf(items[0].scoring_order_golf)
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setIsEventComplete(items[0].is_event_complete)
        )
    }, [items,itemsStatus]);

    const {
        data: itemsTd,
        status: itemsStatusTd,
        // error: itemsErrorTd,
    } = useEventTdId(eventId);

    useEffect(() => {
        itemsStatusTd !== "loading" && itemsTd && itemsTd.length > 0 && (
            setTdId(itemsTd[0].td_id)
        )
        
    }, [itemsTd,itemsStatusTd]);

    const onButtonClick =() => {
        
    }

    return (
        <>
        {isLoggedIn ? (
            tdId === uid
            && <EventTdSection eventId={eventId}/>
        ) : <></>}

        {!isLoggedIn//no logged in. display get started
            ? (
                <CtaSection
                    bg="primary"
                    textColor="light"
                    size="sm"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Ready to score?"
                    subtitle=""
                    buttonText="Score HERE"
                    buttonColor="light"
                    buttonPath="/auth/signup"
                    next={`/event?id=${eventId}`}
                />
            ) :             
                <>
                <CtaSectionLoggedIn
                    eventId={eventId}
                    bg="primary"
                    textColor="light"
                    size="sm"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Ready to register?"
                    subtitle=""
                    buttonText="Get Started"
                    buttonColor="light"
                    buttonPath={`/register?id=${eventId}`}
                />
                </>
        }

        <Meta title={eventName} />

        <SectionHeader
          title={eventName}
          subtitle={eventDate}
          size={1}
        //   spaced={true}
          className="text-center mt-2 mb-0"
        />

        {isLoggedIn && uid!=='' ? (
            <ScorecardBeginComponent eventId={eventId} userId={uid}/>
        ) : <></>}
        


        {scoringOrderGolf !== null ?
            <EventSection
                bg="white"
                textColor="dark"
                size="md"
                bgImage=""
                bgImageOpacity={1}
                title={eventName}
                subtitle=""
                eventId={eventId}
                scoringOrderGolf={scoringOrderGolf}
                isEventComplete={isEventComplete}
            />
        :
            <div>Loading...</div>
        }
        <EventSideGameSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title="Side Games"
            subtitle=""
            eventId={eventId}
        />

        {isLoggedIn ? (
            // <Button 
            // style={{
            //     position: 'fixed', 
            //     display: 'block',
            //     bottom: '30px', 
            //     right: '30px', 
            //     background: '#4477CE',
            //     color: '#35155D',
            //     border: 'none',
            //     borderRadius: '50%',
            //     padding: '15px',
            //     cursor: 'pointer',
            //     fontSize: '30px',
            //     boxShadow: '2px 2px 3px #512B81',
            //     zIndex: '9999',
            //     hover: {background: '#000000'}
            // }}
            //     onClick={onButtonClick()}
            // >
            //     ➕
            // </Button>
            ''
        ) : <>''</>}

        <DisplayScoreboard
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            title={eventName}
            subtitle=""
            eventId={eventId}
            scoringOrderGolf={scoringOrderGolf}
            isEventComplete={isEventComplete}
        />

        </>
    );
  
}

export default EventPage;
