import React, { useEffect } from 'react'
import { useRouter } from '../util/router'; 
import { useState } from 'react';
import ScorecardTable from '../components/ScorecardTable';
import { useGroupScorecardPlayers } from '../util/db'
import { Alert, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScorecardNavbar from './ScorecardNavbar';
import ScorecardSummary from './ScorecardSummary';
import { updateScore } from '../util/db';
import { updateDnf } from '../util/db';
import { updateScorecardIsSubmitted } from '../util/db';
import { Link } from '../util/router';
import ScorecardSettings from '../components/ScorecardSettings';
import { useAuth } from '../util/auth';

function ScorecardPage() {

  //function to increase current hole
  function onRightNav() {
    let thisMaxHole=18
    if(maxHole !== null){
      thisMaxHole = maxHole
    }
    if (currentHole < thisMaxHole) {
      setCurrentHole(currentHole + 1)
    }else{
      setShowSummary(true)
    }
  }
  //function to decrease current hole
  function onLeftNav() {

    if(showSummary){
      setShowSummary(false)
    }else{
      if (currentHole > 1) {
        setCurrentHole(currentHole - 1)
      }else{
        console.log('Min hole reached')
      }
    }
  }

  //function to jump to hole
  function onJumpToHole(hole) {
    setCurrentHole(hole)
    setShowSummary(false)
  }

  function calcMaxHole(){
    let maxHole = 0

    data.forEach((player, index) => {
      if (player.event_entries.hole_scores.length > maxHole) {
        maxHole = player.event_entries.hole_scores.length
      }
    })
    return maxHole
  }

  function toggleSummary(){
    setShowSummary(!showSummary)
  }

  function onUpdateHoleScore(hole_scores_id,event_entries_id, score) {
    const updatedPlayers = [...players]
  
    updatedPlayers.forEach((player, index) => {
      if (player.event_entries_id === event_entries_id) {
        player.event_entries.hole_scores.forEach((hole_score, index) => {
          if (hole_score.hole_scores_id === hole_scores_id) {
            hole_score.score = score
          }
        })
      }
    })
  }
  
  function onSubmitScorecard() {
    let eventId = players[0].event_entries.event_id
  
    // loop through players and calculate total score
    players.forEach((player, index) => {
      let total = 0
      let isDnf=false
      player.event_entries.hole_scores.forEach((score, index) => {
  
        //if player does not have a hole score, set this pplayers total score to null
        if(score.score === null){
          isDnf=true
        }else{
          total += score.score
        }
      })
      player.total_score = total
      if(isDnf){
        player.total_score = null
      }
  
      // if player total score is null, set event_entires is_dnf to true, else updatescore
      if(player.total_score === null){
        updateDnf(player.event_entries_id, {is_dnf: 'true'})
      }else{
        updateScore(player.event_entries_id, {score: player.total_score}, eventId)
      }
  
      //set group_scorecard.is_complete to true
      updateScorecardIsSubmitted(scorecardId,eventId,auth.user)
    })
    
    router.push('/event?event='+eventId)
  }
  
  const router = useRouter();
  const scorecardId = router.query.scorecard
  const [currentHole, setCurrentHole] = useState(1)
  const [maxHole, setMaxHole] = useState()
  const [players, setPlayers] = useState([])
  const [showSummary, setShowSummary] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const { data, status, error } = useGroupScorecardPlayers(scorecardId)
  const [isComplete, setIsComplete] = useState(false)
  const [eventId, setEventId] = useState()
  const auth = useAuth()

  useEffect(() => {
    if (status === "success" && data && data.length > 0) {
      setPlayers(data)
      setMaxHole(calcMaxHole(data))
      setIsComplete(data[0].group_scorecard.is_complete)
      setEventId(data[0].event_entries.event_id)
    }
  }
  , [status, data]) 

  //function to open settings component
  function onSettingsClick(){
    if(showSettings){
      setShowSettings(false)
    }else{
      setShowSettings(true)
    }
  }

  return (
    <>
      {auth.user ?
        <>
          <Container className='mb-5'>
            {/* <Row className='text-right'>
              <Col md="12">
                <span onClick={onSettingsClick}>⚙️</span>
              </Col>
            </Row> */}
            <Row>
              <Col md="12">
                {isComplete
                  ? <Alert variant='success'>Scorecard has been submitted. Edits are disabled.</Alert>
                  : <>
                      {showSettings 
                        ? 
                          <ScorecardSettings scorecardId={scorecardId} players={players} />
                        : 
                          ''
                      }
                  </>
                }
              </Col>
              <Col md="12">
                {showSummary 
                  ? 
                    <ScorecardSummary onJumpToHole={onJumpToHole} isComplete={isComplete} maxHole={maxHole} players={players} onSubmitScorecard={onSubmitScorecard} />
                  : 
                    <ScorecardTable scorecardId={scorecardId} isComplete={isComplete} players={players} currentHole={currentHole} onUpdateHoleScore={onUpdateHoleScore} />    
                }
              </Col>
            </Row>
          </Container>
          <ScorecardNavbar toggleSummary={toggleSummary} showSummary={showSummary} currentHole={currentHole} onLeftNav={onLeftNav} onRightNav={onRightNav}/>
          
          <hr />

          <Container>
              <Link to={'/event?event='+eventId}>Back to Event</Link>
          </Container>
        </> 
      :
        <Container className='mt-2'>          
          <p>
            Please
            <Link to={'/auth/signin'}> Login </Link>
            to view scorecard
          </p>
          
        </Container>
}
    
</>
  )

}

export default ScorecardPage