import { usePlayerNameByEntryId, useScorecard } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useState } from 'react';
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

function DisplayScorecard(props) {

    const eventEntryId = props.eventEntryId
    const [totalScore, setTotalScore] = useState(0);

    //get scorecard for this evententryid
    const {
        data: holesScores,
        status: holesScoresStatus,
        error: holesScoresError,
    } = useScorecard(eventEntryId);

    // get player name
    const {  
        data: playerName, 
        status: playerNameStatus, 
        // error: playerNameError, 
    } = usePlayerNameByEntryId(eventEntryId);

    let playersName = "";
    if(playerNameStatus !== "loading" && playerName !== undefined && playerName.length > 0) {
        playersName=playerName[0].users_public.name
    }


    if(holesScoresStatus !== "loading" && holesScores !== undefined && holesScores.length > 0) {
        const result = holesScores.reduce((total, currentValue) => total = total + currentValue.score,0);
        if(totalScore !== result){
          setTotalScore(result)
        }
      }

    return (
        <>
            {holesScoresStatus === "loading" && (
                <div className="py-5 px-3 align-self-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {holesScoresStatus === "error" && (
                <div className="py-5 px-3 align-self-center">
                    <FormAlert type="error" message={holesScoresError.message} />
                </div>
            )}

            {holesScoresStatus === "success" && holesScores && holesScores.length > 0 && (

                <>
            
                    {/* table for hole scores */}    
                    <Table responsive style={{ width: "100%", tableLayout: "auto", border: "2px solid #35155D",background:"#8CABFF" }}>
                        <thead style={{background:"#35155D", text:"white"}}>
                            <tr>
                                <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D" }}></th>
                                {holesScores.map((item) => (
                                    <th key={item.hole_scores_id} style={{ width: "auto", tableLayout: "auto", border: "2px solid #35155D",color: "white" }}>
                                        <p className="align-bottom">
                                            {item.layout_holes.hole_order}
                                        </p>
                                    </th>
                                ))}
                                <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"white" }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width: "auto", tableLayout: "auto", border: "2px solid #35155D", }}>{playersName}</td>
                                    {holesScores.map((item) => (
                                        <td key={item.hole_scores_id} className="align-bottom" style={{ width: "auto", tableLayout: "auto", border: "2px solid #35155D" }}>
                                            <p>
                                                {/* {
                                                    <button onClick={(e) => router.push(`/holescoreedits?id=${item.hole_scores_id}&event_entries_id=${props.eventEntryId}`)}>{item.score}</button>
                                                } */}
                                                <Link to={`/holescoreedits?id=${item.hole_scores_id}&event_entries_id=${props.eventEntryId}`}>{item.score}</Link>
                                            </p>
                                        </td>
                                    ))}
                                    <td className="align-middle text-center" style={{ width: "auto", tableLayout: "auto", border: "2px solid #35155D", }}>{totalScore}</td>
                            </tr>
                        </tbody>
                    </Table>
                
                <br />
                </>
            )}
            {holesScoresStatus === "success" && holesScores.length === 0 && (
                <p>No scorecard available</p>
            )}
        </>
    );
}

export default DisplayScorecard;