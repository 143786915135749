import React, { useState,useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import EventManageCardConfirmDeleteModal from './EventManageCardConfirmDeleteModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from '../util/router'
import { updateScorecardIsSubmitted } from '../util/db'
import { updateScorecardNotSubmitted } from '../util/db'

function EventManageCardsCard({card,uid}) {
    const [isCardComplete,setIsCardComplete] = useState(card.is_complete)
    // console.log(card.is_complete, ' is card complete?')
    // console.log(card, ' is card complete?')
    
    //useeffect to refresh component when card is complete
    useEffect(() => {
        setIsCardComplete(card.is_complete)
    }, [card.is_complete])
    

    function onLockCard() {
        updateScorecardIsSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to true
        setIsCardComplete(true)
    }

    function onUnlockCard() {
        updateScorecardNotSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to false
        setIsCardComplete(false)
    }

    return (
        <Card className='m-1' style={{borderColor:'#512B81',borderWidth:'10px'}}>
            <Card.Header style={{backgroundColor:'#8CABFF'}}>
                <Card.Title>Card: {card.id}</Card.Title>
                <Card.Subtitle>{card.created_at}</Card.Subtitle>
            </Card.Header>
                
            <Card.Body style={{backgroundColor:'#512B81'}}>    
                {/* //map through card create listgroup of names of players */}
                {card.group_scorecard_players.map((player, index) => (
                    <ListGroup varliant='flush' key={index}>
                        <ListGroup.Item>
                            {player.event_entries.users_public.name}
                            {!player.event_entries.is_dnf 
                                ? <span style={{float:'right'}}>{player.event_entries.score}</span>
                                : <span style={{float:'right'}}>DNF (Did not finish)</span>
                            }
                            
                        </ListGroup.Item>
                    </ListGroup>
                ))}
            </Card.Body>

            <Card.Footer style={{backgroundColor:'#8CABFF'}}>
                <Row>

                    <Col>
                        {/* //if card is complete show lock else show unlock */}
                        {isCardComplete ? (
                            <div style={{cursor:'pointer'}} onClick={onUnlockCard}>🔐</div>
                        ):(
                            <div style={{cursor:'pointer'}} onClick={onLockCard}>🗝️</div>
                        )}
                    </Col>
                

                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Link to={`scorecard?scorecard=${card.id}`}>
                            👀
                        </Link>
                    </Col>

                    <Col>
                        <EventManageCardConfirmDeleteModal 
                            buttonText={'🗑️'}
                            heading={'Delete Card'}
                            bodyText={'Are you sure you want to delete this card?'}
                            cancelText={'Cancel'}
                            confirmText={'Delete'}
                            card={card}
                            
                        />
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default EventManageCardsCard