import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth";
import img from "../media/usdpc.svg"
import Badge from 'react-bootstrap/Badge'
import Row from "react-bootstrap/Row";

function NavbarCustom(props) {
  const auth = useAuth();

  return (
    <Navbar style={{background:'#512B81'}} variant={props.variant} expand={props.expand}>
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            {/* <img
              className="d-inline-block align-top"
              src={img}
              alt="Logo"
              height="100"
            /> */}
            <Row>
            <h1 style={{color:'#4477CE',fontWeight:'bold'}}>
              PuttScores
              </h1>
              <sup>
              {/* <small> */}
                <Badge pill style={{color:'#ffffff'}}>beta</Badge>
              {/* </small> */}
            </sup>
            

            </Row>
            
            
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" style={{backgroundColor:'#4477CE'}} />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            {auth.user && (
              <NavDropdown id="dropdown"  title={
                <span style={{color:'white'}}>Account</span>
            } alignRight={true}>
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/settings/general">
                  <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
                </LinkContainer>
                <Dropdown.Divider />
                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign Out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {!auth.user && (
              <Nav.Item>
                <LinkContainer to="/auth/signin">
                  <Nav.Link active={false}>
                  <span style={{color:'white'}}>Sign in</span>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
