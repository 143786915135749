import React from 'react'

const ScorecardSummaryRow = ({player}) => {


  //function to check whether there are any empty holes_scores.score in players array
//   function checkForEmptyHoles(){
//     let emptyHole = false
//       player.event_entries.hole_scores.forEach((score, index) => {
//         if(score.score === null){
//           emptyHole = true
//         }
//       })
//     return emptyHole
//   }



    const holes = player.event_entries.hole_scores
    // sort holes by hole_order
    holes.sort((a,b) => (a.layout_holes.hole_order > b.layout_holes.hole_order) ? 1 : ((b.layout_holes.hole_order > a.layout_holes.hole_order) ? -1 : 0))

    //calculate this players total score
    let total = 0
    let isEmptyHole = 'false'
    player.event_entries.hole_scores.forEach((score, index) => {
        total += score.score
        if(score.score === null){
            isEmptyHole = 'true'
        }
    })

    return (
        <tr>
            {isEmptyHole === 'true'
              ?
                <td className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black", background:'#ff8cab' }}>{player.event_entries.users_public.name}</td>
              :
                <td className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black" }}>{player.event_entries.users_public.name}</td>
            }
            
            {holes.map((score, index) => 
                <React.Fragment key={index}>
                    {score.score == null ?
                        <td key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black",background:'#ff8cab' }}>-</td>
                    :
                        <td key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black" }}>{score.score}</td>
                    }
                </React.Fragment>
            )}

            {isEmptyHole === 'true'
              ?
              <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black",background:'#ff8cab' }}>{total}</td>
              :
              <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #35155D", color:"black" }}>{total}</td>
            }
            
        </tr>
    )
}

export default ScorecardSummaryRow