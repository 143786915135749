import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from '../util/router';
import { useEventTdId } from '../util/db';
import { useEventName } from '../util/db';
import { Tab,Tabs } from 'react-bootstrap';
import EventManagePlayers from '../components/EventManagePlayers';
import EventManageComplete from '../components/EventManageComplete';
import EventManageCards from '../components/EventManageCards';

function EventManagePage(props) {
  const router = useRouter();
  const eventId = router.query.event;
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : '';
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  const [tdId,setTdId] = useState('')
  const [isEventComplete,setIsEventComplete] = useState(false)
  const [eventName,setEventName] = useState('Event Name')
  const [scoringOrderGolf,setScoringOrderGolf] = useState(null)

  const {
    data: itemsTd,
    status: itemsStatusTd,
    // error: itemsErrorTd,
  } = useEventTdId(eventId);

  useEffect(() => {
    itemsStatusTd !== "loading" && itemsTd && itemsTd.length > 0 && (
        setTdId(itemsTd[0].td_id)
    )
    
}, [itemsTd,itemsStatusTd]);

  useEffect(() => {
    if(uid !== ''){
        setIsLoggedIn(true)
    }else{
        setIsLoggedIn(false)
    }
  }, [uid])

  const {
    data: items,
    status: itemsStatus,
    // error: itemsError,
} = useEventName(eventId);

useEffect(() => {
    itemsStatus !== "loading" && items && items.length > 0 && (
        setEventName(items[0].name)
    )
}, [items,itemsStatus]);

useEffect(() => {
    itemsStatus !== "loading" && items && items.length > 0 && (
        setScoringOrderGolf(items[0].scoring_order_golf)
    )
}, [items,itemsStatus]);

useEffect(() => {
    itemsStatus !== "loading" && items && items.length > 0 && (
        setIsEventComplete(items[0].is_event_complete)
    )
}, [items,itemsStatus]);

  return (
    <>
      <div className="text-right">
        
        <Link to={`/event?event=${eventId}`}>
          Back to view event
        </Link>
      </div>

      {isLoggedIn ? (
        tdId === uid
        && <>
        <div className='text-center'>
          <h1>Manage {eventName}</h1>
          </div>
          <Tabs
            defaultActiveKey="players"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="cards" title="Cards">
              <EventManageCards uid={uid} eventId={eventId}/>
            </Tab>
            <Tab eventKey="players" title="Players">
              <EventManagePlayers eventId={eventId} scoringOrderGolf={scoringOrderGolf} />
            </Tab>
            <Tab eventKey="complete" title="Complete">
              <EventManageComplete eventId={eventId} isEventComplete={isEventComplete}/>
            </Tab>
          </Tabs>
        </>
      ) : <Container>
          Please log in to manage event.<br/>
          <Link to={`/auth/signin`}>
            Sign in
          </Link>
      </Container>}

    </>
  )
}

export default EventManagePage