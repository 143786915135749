import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteHoleScores, removeGroupScorecardPlayer, removeGroupScorecard, updateScore } from '../util/db'
import { updateDnf } from '../util/db'

function EventManageCardConfirmDeleteModal(props) {
  const [show, setShow] = useState(false);
  const card=props.card

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  async function handleConfirmDelete() {

    function deleteHoleScoresForPlayer(player) {
      console.log(player.event_entries.id,'player.event_entries.id')
      deleteHoleScores(player.event_entries.id).then((res)=>{
          console.log('deleted hole scores',player.event_entries.users_public.name)
        }).catch((err)=>{
          console.log('error deleting hole scores')
      })
    }

    async function removePlayerFromScorecard(player) {
      console.log(player.id,'player.id')

      //remove player from group_scoracard_players
      removeGroupScorecardPlayer(player.id).then((res)=>{
        console.log('removed group_scorecard_player',player.event_entries.users_public.name)
      }).catch((err)=>{
        console.log('error removing player from group_scorecard_players')
      })
    }

    function removePlayersScore(player) {
      console.log(player.event_entries.id,'player.event_entries.id')
      // //remove players final score
      updateScore(player.event_entries.id,{score:null},card.event_id).then((res)=>{
          console.log('updated score to null',player.event_entries.users_public.name)
      }).catch((err)=>{
          console.log('error updating score to null')
      })
    }

    function removePlayersDnf(player) {
      console.log(player.event_entries.id,'player.event_entries.id')
            // if player was marked as DNF, unmark them
            if(player.event_entries.is_dnf ===true){

              updateDnf(player.event_entries.id, {is_dnf: 'false'}).then((res)=>{
                  console.log('updated is_dnf to false')
              }).catch((err)=>{
                  console.log('error updating is_dnf to false')
              })
            }
          }

    //loop through players
    const unresolvedPromises = card.group_scorecard_players.map((player, index) => {      
      //delete hole_scores for this event_entries_id
      deleteHoleScoresForPlayer(player)
      //remove player from group_scoracard_players
      removePlayerFromScorecard(player)
      //remove players final score
      removePlayersScore(player)
      // if player was marked as DNF, unmark them
      removePlayersDnf(player)
    })

    const results = await Promise.all(unresolvedPromises).then((res)=>{
      console.log('all promises resolved')
      console.log(unresolvedPromises,'unresolvedPromises')
      console.log(res,'res')
        //remove scorecard from group_scorcard
        removeGroupScorecard(card.id,card.event_id).then((res)=>{
          console.log('removed group_scorecard')
        })
      }
    ).catch((err)=>{
      console.log('error removing group_scorecard')
    })
  

    handleClose()
  }



  async function handleConfirmDeleteOld() {
    
    const unresolvedPromises = card.group_scorecard_players.map((player, index) => {      
      
      //delete hole_scores for this event_entries_id







    })

    const results = await Promise.all(unresolvedPromises).then((res)=>{
      console.log('all promises resolved')
      console.log(unresolvedPromises,'unresolvedPromises')
      console.log(res,'res')
        //remove scorecard from group_scorcard
        // removeGroupScorecard(card.id,card.event_id).then((res)=>{
        //   console.log('removed group_scorecard')
        // })    
      }
    ).catch((err)=>{
      console.log('error removing group_scorecard')
    })

    handleClose()

  }

  const handleConfirm = () => {

    


    //loop through players
    card.group_scorecard_players.map((player, index) => {

      //delete hole_scores for this event_entries_id
      deleteHoleScores(player.event_entries.id).then((res)=>{
        console.log('deleted hole scores',player.event_entries.users_public.name)

        //remove player from group_scoracard_players
        removeGroupScorecardPlayer(player.id).then((res)=>{

          //THIS NEEDS TO BE OUTSIDE OF THE LOOP

          //remove scorecard from group_scorcard
          removeGroupScorecard(card.id,card.event_id).then((res)=>{
            console.log('removed group_scorecard')
          })    

        }).catch((err)=>{
          console.log('error removing player from group_scorecard_players')
        })
      }).catch((err)=>{
        console.log('error deleting hole scores')
      })

      //remove players final score
      updateScore(player.event_entries.id,{score:null},card.event_id).then((res)=>{
          console.log('updated score to null',player.event_entries.users_public.name)
      }).catch((err)=>{
          console.log('error updating score to null')
      })

      // if player was marked as DNF, unmark them
      if(player.event_entries.is_dnf ===true){

        updateDnf(player.event_entries.id, {is_dnf: 'false'}).then((res)=>{
            console.log('updated is_dnf to false')
        }).catch((err)=>{
            console.log('error updating is_dnf to false')
        })
      }
    })

    //close modal
    handleClose()
  }

  return (
    <>
      <div variant="primary" onClick={handleShow} style={{cursor:'pointer',display:'flex', justifyContent:'right'}}>
        {props.buttonText}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading?props.heading:''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.bodyText?props.bodyText:'Are you sure?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {props.cancelText?props.cancelText:'Cancel'}
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete}>
            {props.confirmText?props.confirmText:'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventManageCardConfirmDeleteModal;