import React from 'react'
import { usePlayersNotPlayingYet } from '../util/db';
import Spinner from "react-bootstrap/Spinner";
import ScorecardPlayerSelect from './ScorecardPlayerSelect';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ScorecardCreateComponent = (props) => {
  const eventId = props.eventId
  const eventEntryId = props.eventEntryId

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = usePlayersNotPlayingYet(eventId);
  
  return (
    <>
      {itemsStatus === "error" && (
        console.log(itemsError, 'error')
      )}
      
      <Container>
        <Row>
          <Col>
            <h6 className="justify-content-center text-center">Available Players</h6>
            <div>
              {itemsStatus === "loading" && (
                  <div className="py-5 px-3 align-self-center">
                  <Spinner animation="border" variant="primary" />
                  </div>
              )}

              {itemsStatus === "success" && items && items.length > 0 && (
                  <ScorecardPlayerSelect items={items} eventId={props.eventId} eventEntryId={props.eventEntryId} />
              )}

              {itemsError && (
                  console.log(itemsError.message))
              }
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ScorecardCreateComponent