import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import EventSideGamePlayers from "./EventSideGamePlayers";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useEventSideGames } from "../util/db";

function EventSideGameSection(props) {
  const auth = useAuth();
  const router = useRouter();

  //event id for this event
  const eventId = props.eventId

  //get side game ids for this event
  const {
    data: items,
    status: itemsStatus,
    error: itemsError
    
  } = useEventSideGames(eventId)
  
  return (
    
      <>

          {itemsStatus !== "loading" && items && items.length > 0 && (
      <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
            {/* //loop through side games and print players */}
            <div>
              {items.map((item2, index2) => (
                <div key={index2} >
                <Container>
                  <SectionHeader
                    title={item2.side_game_name}
                    subtitle={props.subtitle}
                    size={1}
                    spaced={true}
                    className="text-center"
                  />
                  <Row>
                    <Col>
                      <EventSideGamePlayers sideGameId={item2.id} />
                    </Col>
                  </Row>
                </Container>
                <br />
                </div>
              ))}
            </div>
      </Section>

          )}

          
      </>
  );
}

export default EventSideGameSection;
